<template>
    <div class="quartz">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>城市配司机用户注册</span>
            </div>
            <div class="text item">
                <el-form :model="dataForms"  ref="dataForms" :rules="rules" label-width="110px" class="demo-ruleForm" size="mini">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="用户名" prop="username">
                                <el-input auto-complete="off" v-model="dataForms.username"  @input="accountInput"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="用户密码" prop="password">
                                <el-input auto-complete="off" v-model="dataForms.password" show-password></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="再次确认密码" prop="confirmPassword">
                                <el-input auto-complete="off" v-model="dataForms.confirmPassword" @blur="onInputBlur" show-password></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="用户真实姓名" prop="name">
                                <el-input auto-complete="off" v-model="dataForms.name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="性别" prop="gender">
                                <el-select v-model="dataForms.gender" placeholder="请选择状态">
                                    <el-option label="男" value="1"></el-option>
                                    <el-option label="女" value="0"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="用户手机号" prop="phone">
                                <el-input auto-complete="off" v-model="dataForms.phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="短信验证" prop="code" class="pr">
                                <el-input auto-complete="off" style="width:80px;" v-model="dataForms.code"></el-input>
                                <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
                                    <span v-show="show">获取验证码</span>
                                    <span v-show="!show" class="count">{{count}} s</span>
                                </button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="关联承运商编码" prop="carrierId">
                                <el-input auto-complete="off" v-model="dataForms.carrierId"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="司机驾驶证号" prop="driverLicense">
                                <el-input auto-complete="off" v-model="dataForms.driverLicense"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <City @addlist="getCity" :addList="addList"></City>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="司机驾驶证附件照片" :required="true">
                                <el-upload
                                        class="upload"
                                        :class="{ hide: hideUp,uoloadSty:showBtnImg,disUoloadSty:noneBtnImg}"
                                        :action="getUploadUrl"
                                        list-type="picture-card"
                                        :limit="1"
                                        accept="image/*"
                                        name="image"
                                        :on-success="handleUpSuccess"
                                        :on-error="handleUpError"
                                        :on-change="handleUpUpload"
                                        :on-preview="handleUpPreview"
                                        :on-remove="handleUpRemove">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col style="text-align: center">
                            <el-button type="primary" size="mini" @click="cityUserReg">保存</el-button>
                            <el-button type="warning" size="mini" @click="resetForm">重置</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<script>
    import axios from 'axios'
    import qs from 'qs'
    import City from "../../components/common/cityLinkage.vue"
    export default {
        data(){
            return{
                tydvalue:true,
                transStatusvalue:false,
                show: 'false',
                count: '',
                dataForms:{
                    username: '',
                    password:'',
                    tenantId:"",
                    confirmPassword:'',
                    name:'',
                    gender:'1',
                    phone:'',
                    carrierId:'',
                    driverLicense:'',
                    driverLicenseUrl:'',
                    code:''
                },
                hideUp:false,
                hideUp1:false,
                hideUp2:false,
                hideUp3:false,
                hideBack:false,
                hideBusiness:false,
                imagesUp:[],
                imagesBack:[],
                imagesBusiness:[],
                fileList:[],
                dialogImageUrl:'',
                dialogImageUrl1:'',
                dialogImageUrl2:'',
                dialogImageUrl3:'',
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisible3: false,
                showBtnImg:true,
                showBtnImg1:true,
                showBtnImg2:true,
                showBtnImg3:true,
                noneBtnImg:false,
                noneBtnImg1:false,
                noneBtnImg2:false,
                noneBtnImg3:false,
                limitCountImg:1,
                limitCountImg1:1,
                limitCountImg2:1,
                limitCountImg3:1,
                rules: {
                    username: [
                        { required: true, min: 7,message: '请输入用户名', trigger: 'blur' },
                        { min: 7, max:12,message: "用户名必须填写7-12位的数字和字母组合", trigger: "change" },
                        {
                            pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,12}$',
                            message: "用户名必须数字+字母组合",
                            trigger: "blur"
                        }
                    ],
                    password: [
                        { required: true,message: '请输入密码', trigger: 'blur' },
                        { min: 8, max: 12, message: "请输字母、数字、特殊字符组合的入8-12位密码", trigger: "change" },
                        {
                            pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                            message: "密码设置需要数字+字母+特殊符合",
                            trigger: "blur"
                        }
                    ],
                    confirmPassword: [
                        { required: true,message: '请输入密码', trigger: 'blur' },
                        { min: 8, max: 12, message: "请输字母、数字、特殊字符组合的入8-12位密码", trigger: "change" },
                        {
                            pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                            message: "密码设置需要数字+字母+特殊符合",
                            trigger: "blur"
                        }
                    ],
                    name: [
                        { required: true, message: '请输入您的真实姓名', trigger: 'blur' }
                    ],
                    code: [
                        { required: true, message: '手机验证码必填', trigger: 'blur' }
                    ],
                    phone:[
                        { required: true, message: "请输入手机号码", trigger: "blur" },
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: '^1[345789]\\d{9}$',
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ],
                    carrierId: [
                        { required: true, message: '请输入您要注册主体的承运商编码', trigger: 'blur'}
                    ],
                    driverLicense: [
                        { required: true, message: '请输入您的驾驶证号码', trigger: 'blur' }
                    ]
                },
                addList: {
                    province: "",
                    city: "",
                    area: ""
                }
            }
        },
        components:{ //引入组件后注册组件
            City
        },
        computed:{
            getUploadUrl(){
                let filePath = `${this.$filePath}`;
                return filePath
            }
        },
        mounted:function () {

        },
        methods:{
            getCity(data) {
                this.addList.province = data.province;
                this.addList.city = data.city;
                this.addList.area = data.area;
            },
            onInputBlur() {
                //输入密码时验证
                if (this.dataForms.password === this.dataForms.confirmPassword) {

                }else {
                    this.$message.error('两次输入密码不一致哦，请重新输入');
                }
            },
            accountInput(val){//账号的实时输入
                let codeReg = new RegExp("[A-Za-z0-9]+"), //正则 英文+数字；
                    len=val.length,
                    str='';
                for(var i=0;i<len;i++){
                    if(codeReg.test(val[i])){
                        str+=val[i];
                    }
                }
                this.dataForms.username=str;
            },
            // 获取短信验证码
            getCode() {
                //axios请求
                if(this.dataForms.phone == ''){
                    this.$alert('请输入手机号', '提示', {
                        confirmButtonText: '确定',
                    });
                }else {
                    // 验证码倒计时
                    if (!this.timer) {
                        this.count = 60;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= 60) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000);
                    }
                    axios.post(`${this.$apiPath}/api/pm/login/send?phone=`+ this.dataForms.phone).then(res=>{
                        if(res.data.success == true){
                            this.$message({
                                message: '验证码已发送至您手机，注意查收',
                                type: 'success'
                            });
                        }
                    })
                }
            },
            cityUserReg(){
                this.$refs["dataForms"].validate(valid => {
                    if (valid) {
                        //城市配用户注册
                        if(this.addList.province == ''){
                            this.$message.error('地址信息必填');
                            return false
                        }else if(this.addList.city == ''){
                            this.$message.error('地址信息必填');
                            return false
                        }else if(this.addList.area == ''){
                            this.$message.error('地址信息必填');
                            return false
                        }else {
                            const data = new URLSearchParams();
                            data.append('username', this.dataForms.username);
                            data.append('password', this.dataForms.password);
                            data.append('tenantId', this.dataForms.tenantId);
                            data.append('confirmPassword', this.dataForms.confirmPassword);
                            data.append('name', this.dataForms.name);
                            data.append('gender', this.dataForms.gender);
                            data.append('phone', this.dataForms.phone);
                            data.append('carrierId', this.dataForms.carrierId);
                            data.append('driverLicense', this.dataForms.driverLicense);
                            data.append('driverLicenseUrl', this.dataForms.driverLicenseUrl);
                            data.append('code', this.dataForms.code);
                            data.append('proviceAdcode', this.addList.province);
                            data.append('cityAdcode', this.addList.city);
                            data.append('regionAdcode', this.addList.area);
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/login/driver/register`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$alert('注册成功，请等待审批', '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.$router.replace({ path: "/login" });
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                }
                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });

            },
            resetForm(){
                this.$refs.dataForms.resetFields();
            },
            //---------------------------------------司机驾驶证副本--------------------------------
            handleUpSuccess(response, file, fileList) {
                this.dialogImageUrl = `${this.$imageiPPath}`+response.data;
                this.dataForms.driverLicenseUrl = response.data;
                this.imagesUp = response.data;
                this.hideUp = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError(err, file, fileList) {},
            // 删除图片
            handleUpRemove(file, fileList) {
                this.imagesUp = [];
                this.hideUp = false;
                this.dataForms.driverLicenseUrl = '';
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            // 预览图片
            handleUpPreview(file) {
                this.dialogVisible = true;
            },
            // 上传图片
            handleUpUpload(file, fileList) {
                this.hideUp = true;
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            //---------------------------------------司机行驶证附件照片--------------------------------
            handleUpSuccess1(response, file, fileList) {
                this.dialogImageUrl1 = `${this.$imageiPPath}`+response.data;
                this.dataForms.vehicleLicenseUrl = response.data;
                this.hideUp1 = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError1(err, file, fileList) {},
            // 删除图片
            handleUpRemove1(file, fileList) {
                this.hideUp1 = false;
                this.dataForms.vehicleLicenseUrl = '';
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            // 预览图片
            handleUpPreview1(file) {
                this.dialogVisible1 = true;
            },
            // 上传图片
            handleUpUpload1(file, fileList) {
                this.hideUp1 = true;
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            //---------------------------------------司机身份证正面--------------------------------
            handleUpSuccess2(response, file, fileList) {
                this.dialogImageUrl2 = `${this.$imageiPPath}`+response.data;
                this.dataForms.driverIdentityPositiveUrl = response.data;
                this.hideUp2 = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError2(err, file, fileList) {},
            // 删除图片
            handleUpRemove2(file, fileList) {
                this.hideUp2 = false;
                this.dataForms.driverIdentityPositiveUrl = '';
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            // 预览图片
            handleUpPreview2(file) {
                this.dialogVisible2 = true;
            },
            // 上传图片
            handleUpUpload2(file, fileList) {
                this.hideUp2 = true;
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            //---------------------------------------司机身份证反面--------------------------------
            handleUpSuccess3(response, file, fileList) {
                this.dialogImageUrl3 = `${this.$imageiPPath}`+response.data;
                this.dataForms.driverIidentityBackUrl = response.data;
                this.hideUp3 = fileList.length == 1;
                this.$message.success(response.msg);
            },
            handleUpError3(err, file, fileList) {},
            // 删除图片
            handleUpRemove3(file, fileList) {
                this.hideUp3 = false;
                this.dataForms.driverIidentityBackUrl = '';
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            },
            // 预览图片
            handleUpPreview3(file) {
                this.dialogVisible3 = true;
            },
            // 上传图片
            handleUpUpload3(file, fileList) {
                this.hideUp3 = true;
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            }
        }
    }
</script>
<style>
    .pr {
        position: relative;
    }
    .code-btn {
        width: 85px;
        height: 29px;
        position: absolute;
        right: 10px;
        z-index: 222;
        color: #409EFF;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        background-color: #fff;
        cursor: pointer;
    }
    .waybillDo .grid-content{
        height: 100%;
    }
    .waybillDo .box-card{
        height: 100%;
    }
    .waybillDo .el-card__header{
        padding: 5px 10px;
    }
    .waybillDo .el-card__body{
        padding: 5px;
    }
    .uoloadSty .el-upload--picture-card{
        width:157px;
        height:110px;
        line-height:110px;
    }
    .disUoloadSty .el-upload--picture-card{
        display:none;   /* 上传按钮隐藏 */
    }
</style>
<style scoped>
    body{
        position: relative;
    }
    .quartz{
        width: 95%;
        height: 90%;
        position: absolute;
        left:0;
        right: 0;
        top:0;
        bottom: 0;
        margin: auto;
    }
    .quartz .el-card{
        width: 100%;
        height: 100%;
    }
    .text {
        font-size: 14px;
    }
    .item {
        margin-bottom: 18px;
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }

    .box-card {
        width: 480px;
    }
</style>
